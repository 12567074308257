import React, { ReactNode } from "react";

import {
  GlobeAltIcon,
  ClockIcon,
  UserCircleIcon,
  CloudArrowUpIcon,
  ArrowTopRightOnSquareIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useAccount, useMsal } from "@azure/msal-react";

function Sidebar() {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0] || {});
  const apiUrl = process.env.REACT_APP_API_URL;
  return (
    <div className="flex flex-col h-screen w-64 bg-blue-500 text-white">
      <div className="flex items-center p-4">
        <img src="/logo.png" alt="Logo" className="h-8 mr-2" />
        {/* <span className="text-xl font-bold">Utilities</span> */}
      </div>
      <nav className="flex flex-col mt-8">
        <button className="w-full flex justify-between items-center p-2 rounded">
            <span>GradeXpert</span>
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
        </button>
        <div id="gradeXpertMenu" className="ml-4 space-y-2">
          <SidebarItem text="Migration" path="GradeXpert/migration/list">
            <CloudArrowUpIcon className="h-5 w-5 text-white"></CloudArrowUpIcon>
          </SidebarItem>
        </div>
        <hr className="my-4 border-gray-200" />
        <button className="w-full flex justify-between items-center p-2 rounded">
            <span>Accelerus</span>
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
        </button>
        <div id="accelerusMenu" className="ml-4 space-y-2 ">
          <SidebarItem text="Elastic Pool" path="/accelerus/configure/db">
            <AdjustmentsHorizontalIcon className="h-5 w-5 text-white"></AdjustmentsHorizontalIcon>
          </SidebarItem>
        </div>
        <hr className="my-4 border-gray-200" />
        <button className="w-full flex justify-between items-center p-2 rounded">
            <span>Xuno</span>
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
            </svg>
        </button>
        <div id="XunoMenu" className="ml-4 space-y-2 ">
          <SidebarItem text="Xuno Backup" path="/xuno/configure/backup">
            <AdjustmentsHorizontalIcon className="h-5 w-5 text-white"></AdjustmentsHorizontalIcon>
          </SidebarItem>
        </div>
      </nav>
      <div className="flex mt-auto">
        <SidebarItem text={`${account?.name}`} path="/settings">
          <UserCircleIcon className="h-7 w-7 text-white"></UserCircleIcon>
        </SidebarItem>
      </div>
    </div>
  );
}
type SidebarType = {
  children: ReactNode;
  text: string;
  path: string;
};

function SidebarItem({ children, text, path }: SidebarType) {
  return (
    <Link
      to={{ pathname: path }}
      className="flex items-center p-2 hover:bg-blue-60"
    >
      <span className="mr-2">{children}</span>
      <span>{text}</span>
    </Link>
  );
}

export default Sidebar;
