import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { useLoading } from "../shared/LoadingContext";
import { toast } from "react-toastify";
import formatTimeTo12Hour from "../shared/Common";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

const Jobs = () => {
  const { instance, accounts } = useMsal();
  const { setLoading } = useLoading();

  const [jobs, setJobs] = useState([]);
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/xuno`;
  const apiScope = "api://utilities/managefunctionapp";

  const getAccessToken = async () => {
    const accessToken = await instance.acquireTokenSilent({
      scopes: [apiScope],
      account: accounts[0],
    });
    return accessToken.accessToken;
  };

  const fetchJobs = async () => {
    try {
      setLoading(true);
      const accessToken = await getAccessToken();
      const response = await axios.get(`${apiUrl}/jobs`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setJobs(response.data as []);
    } catch (ex) {
    } finally {
      setLoading(false);
    }
  };

  const updateJob = async (
    jobId: string,
    scheduleTime: string,
    isEnabled: boolean
  ) => {
    try {
      setLoading(true);
      const accessToken = await getAccessToken();
      await axios.put(
        `${apiUrl}/updatejobs/${jobId}`,
        {
          scheduleTime: formatTimeTo12Hour(scheduleTime),
          isEnabled: isEnabled,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      fetchJobs();
      toast.success(`Job updated updated successfully for ${jobId}`);
    } catch {
      toast.error("Error occured!");
    } finally {
      setLoading(false);
    }
  };

  const convertTo24Hour = (time: string) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":");
  
    if (modifier === "PM" && hours !== "12") {
      hours = String(parseInt(hours, 10) + 12);
    } else if (modifier === "AM" && hours === "12") {
      hours = "00";
    }
  
    return `${hours}:${minutes}`;
  };

 

  useEffect(() => {
    fetchJobs();
  }, [instance, accounts]);

  return (
    <div>
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-lg font-bold text-start">XUNO | Backup Jobs</h2>
      </div>
      <div className="border-b border-gray-200 w-full mt-2"></div>


    <div className="flex items-center gap-4 rounded-lg bg-sky-100  p-6 shadow-md outline outline-black/5 dark:bg-gray-800">
      {/* <span className="inline-flex shrink-0 rounded-full border p-2 ">
        <InformationCircleIcon color="blue"></InformationCircleIcon>
      </span> */}
      <div>
        <p className="text-gray-700 dark:text-gray-400">
          Database is backed up in storage account
          <span className="font-medium text-gray-950 dark:text-white">xunohosteddata</span> inside container
          <span className="font-medium text-gray-950 dark:text-white">--databasename--/databasebackup/--server--/dateformat.zip</span>
        </p>
        <p className="mt-1 block text-gray-500">Example. 10rs8v/databasebackup/xunoa-mysql-6wycriciitf2e.mysql.database.azure.com/databasebackup/10rs8v_20240624-051555.zip</p>
      </div>
    </div>
      <div className="migration-list mt-4">
        <div className="container mx-auto  border rounded-md bg-white">
          <JobTable data={jobs} onsave={updateJob} />
        </div>
      </div>
    </div>
  );
};

const JobTable = ({data, onsave}: {data:any | [] | never[], onsave: (jobId: string, scheduleTime: string, isEnabled: boolean)=> {} }) => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    if(data && data.length){
      setJobs( data.map((row: any) => ({
        ...row,
      })));
    }
  }, [data]);

  const handleToggle = (index: any, checked : boolean) => {
    setJobs((prevRows) => {
      const newJobs: any = [...prevRows];
      newJobs[index].isEnabled = checked;
      return newJobs;
    });
  };

  const handleTimeChange = (index: any, value: any) => {
    setJobs((jobs) => {
      const newJobs: any = [...jobs];
      newJobs[index].scheduleTimeValue = value;
      return newJobs;
    });
  };

  const updateJob = async (
    index: any
  ) => {
    if(index > jobs.length)
      return;
    var job: any = jobs[index];
    onsave(job.rowKey, job.scheduleTimeValue, job.isEnabled )
  };
  
  return ( <table className="w-full table-auto border-collapse">
    <thead>
      <tr className="text-left">
        <th className="border-b-2 border-gray-400 p-2">ID #</th>
        <th className="border-b-2 border-gray-400 p-2">Server ID</th>
        <th className="border-b-2 border-gray-400 p-2">Server</th>
        <th className="border-b-2 border-gray-400 p-2">
          Last Backup
        </th>
        <th className="border-b-2 border-gray-400 p-2">
          Schedule Time
        </th>
        <th className="border-b-2 border-gray-400 p-2">Enabled</th>
        <th className="border-b-2 border-gray-400 p-2">Actions</th>
      </tr>
    </thead>
    <tbody>
      {jobs.map((job: any, index) => (
        <tr key={job.rowKey}>
          <td className="border-b border-gray-300 p-2">{job.rowKey}</td>
          <td className="border-b border-gray-300 p-2">
            {job.serverId}
          </td>
          <td className="border-b border-gray-300 p-2">{job.server}</td>
          <td className="border-b border-gray-300 p-2">{job.lastRun}</td>
          <td className="border-b border-gray-300 p-2">
            <input
              type="time"
              value={job.scheduleTimeValue}
              onChange={(e) => handleTimeChange(index, e.target.value)}
              className="border border-gray-400 p-1 bg-white"
            />
          </td>
          <td className="border-b border-gray-300 p-2">
            <input
              type="checkbox"
              checked={job.isEnabled}
               onChange={(e) => handleToggle(index, e.target.checked)}
            />
          </td>
          <td className="border-b border-gray-300 p-2">
            <button 
            className="px-4 py-2 bg-blue-500 text-white border-none cursor-pointer"
            onClick={(e)=> { 
              updateJob(index);
            }}>
              Save
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>);
}

export default Jobs;
