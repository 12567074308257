const  formatTimeTo12Hour = (time: string) => {
    if (!time) return ""; // Handle empty input

    const [hour, minute] = time.split(":");
    let period = "AM";
    let formattedHour = parseInt(hour, 10);

    if (formattedHour >= 12) {
      period = "PM";
      if (formattedHour > 12) formattedHour -= 12; // Convert to 12-hour format
    }
    if (formattedHour === 0) formattedHour = 12; // Handle midnight case

    return `${formattedHour < 10 ? '0'+ formattedHour: formattedHour }:${minute} ${period}`;
  };
  
  export default formatTimeTo12Hour;